import React from "react"
import ReactHover from "react-hover"
import { groupBy } from "lodash"

const Works = ({ works }) => {
  const worksByYear = groupBy(works, "Year")
  const years = Object.keys(worksByYear)
    .sort()
    .reverse()

  return (
    <section id="works" className="text-works py-12 overflow-hidden">
      <h1 className="text-2xl mb-12">Works</h1>
      <div className="flex flex-wrap -mx-8 cursor-pointer">
        {years.map((year, index) => (
          <div className="md:w-1/2 lg:w-1/3 px-8 mb-12" key={year}>
            <h3 className="font-semibold mb-6">{year}</h3>
            <ul className="font-mono text-sm">
              {worksByYear[year].map(work => (
                <Work key={work.SKU} work={work} index={index} />
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Works

const Work = ({ work, index }) => {
  return (
    <ReactHover
      options={{
        followCursor: true,
        shiftX: index % 3 == 2 ? -250 : 20,
        shiftY: 20,
      }}
    >
      <ReactHover.Trigger type="trigger">
        <dl className="pb-4 flex">
          <dt className="font-light flex-shrink-0">NCS-{work.SKU}&nbsp;</dt>
          <dd>
            {work.Artist}
            <em className="block">{work.Title}</em>
          </dd>
        </dl>
      </ReactHover.Trigger>
      <ReactHover.Hover type="hover">
        <img
          className="w-64 h-64"
          src={work.Cover && work.Cover.length > 0 && work.Cover[0].url}
          alt={work.title}
        />
      </ReactHover.Hover>
    </ReactHover>
  )
}
