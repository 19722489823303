import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Works from "../components/Works"
import Gear from "../components/Gear"
import Footer from "../components/Footer"
import ReactHover from "react-hover"
import useSWR from "swr"
import Moon from "../images/moon.svg"

const fetcher = url => fetch(url).then(r => r.json())

const IndexPage = ({
  data: {
    works: { nodes: workNodes },
    gear: { nodes: gearNodes },
  },
}) => {
  const { data: weather } = useSWR("/.netlify/functions/weather", fetcher)
  const works = workNodes.map(n => n.data)
  const gear = gearNodes.map(n => n.data)

  return (
    <Layout hideFooter={true}>
      <SEO title="Niles City Sound, Fort Worth, TX" />

      <ReactHover options={{ followCursor: true, shiftX: 20, shiftY: 20 }}>
        <ReactHover.Trigger type="trigger">
          <section
            id="studio"
            className="text-studio text-2xl md:text-3xl md:w-2/3 pb-24 pt-4"
          >
            <p className="py-2">
              <strong>
                <em className="font-semibold">Niles City Sound</em>
              </strong>{" "}
              is a studio based in Fort Worth, TX
            </p>
            <p className="py-2">
              A collection of producers, engineers and musicians, a venue for
              performance and creation and celebration.
            </p>
            <p className="py-2">
              Our focus is to create lasting musical experiences in an engaging
              and appealing environment, modular acoustics, and an extensive and
              unique collection of tools make that a reality.
            </p>
          </section>
        </ReactHover.Trigger>
        <ReactHover.Hover type="hover">
          <div className="font-mono text-studio text-xs">
            Fort Worth, TX
            <br />
            {weather && weather.main && parseInt(weather.main.temp)}° and{" "}
            {weather && weather.weather[0] && weather.weather[0].main}
            <br />
            <Time />
          </div>
        </ReactHover.Hover>
      </ReactHover>

      <Works works={works} />

      <Gear gear={gear} />

      <Footer />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query WorksIndex {
    works: allAirtable(
      filter: { table: { eq: "Works" } }
      sort: { fields: data___Position, order: ASC }
    ) {
      nodes {
        data {
          SKU
          Title
          Artist
          Year
          Tracklist
          Apple_Music
          Spotify
          Slug
          Bandcamp
          YouTube
          Cover {
            url
          }
        }
      }
    }

    gear: allAirtable(filter: { table: { eq: "Gear" } }) {
      nodes {
        data {
          Item
          Manufacturer
          Quantity
          Studio_Notes
        }
      }
    }
  }
`

const Time = () => {
  const [time, setTime] = useState()

  useEffect(() => {
    const timeout = setTimeout(() => {
      const date = new Date()
      setTime(date.toLocaleTimeString("en-US", { timeZone: "America/Chicago" }))
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [time])

  return <>{time}</>
}

const Spinner = () => {
  const { scrollY } = useViewportScroll()
  const y = useTransform(
    useTransform(scrollY, v => v / 4),
    [0, 1],
    {
      clamp: false,
    }
  )

  return (
    <div className="fixed right-0 w-56" style={{ top: "50px", zIndex: -10 }}>
      <motion.div style={{ rotate: y, transformOrigin: "center right" }}>
        <img src={Moon} alt="" />
      </motion.div>
    </div>
  )
}
