import React from "react"
import ReactHover from "react-hover"

const Gear = ({ gear }) => {
  return (
    <section id="gear" className="text-gear py-12">
      <h1 className="text-2xl mb-12">Gear</h1>
      <div className="font-mono text-sm cursor-pointer">
        {gear.map(item => (
          <ReactHover
            options={{
              followCursor: true,
              shiftX: 20,
              shiftY: 20,
            }}
          >
            <ReactHover.Trigger type="trigger">
              <div className="flex text-2xl" key={item.Item}>
                <div className="w-1/12 pr-16 py-1">{item.Quantity}</div>
                <div className="w-4/12 pr-16 py-1">{item.Item}</div>
                <div className="w-4/12 pr-16 py-1">{item.Manufacturer}</div>
              </div>
            </ReactHover.Trigger>
            <ReactHover.Hover type="hover">
              <p className="max-w-sm">{item.Studio_Notes}</p>
            </ReactHover.Hover>
          </ReactHover>
        ))}
      </div>
    </section>
  )
}

export default Gear
